import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import MaterialTable from 'material-table';
import { requestTableData, deleteExceptions } from '../actions/siteActions';
import { getTableData } from '../selectors/siteSelectors';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonProgress from '../../utils/ButtonProgress';


const mapStateToProps = (state: any) => ({
    exceptionsData: getTableData(state, '/api/admin/exceptions')
});

const mapDispatchToProps = {
    requestTableData,
    deleteExceptions
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ExceptionsProps = ConnectedProps<typeof connector> & {};

const Exceptions: FunctionComponent<ExceptionsProps> = (props) => {
    const { exceptionsData } = props;
    const { requestTableData, deleteExceptions } = props;
    const authContext = useAuth0();

    React.useEffect(() => {
        requestTableData(authContext, '/api/admin/exceptions');
    }, [authContext, requestTableData]);

    return (
        <Box>
            { exceptionsData == null ? <ButtonProgress /> :
                <MaterialTable
                    title='Exceptions'
                    columns={[
                        { title: 'Count', field: 'groupCount', type: 'numeric' },
                        { title: 'Last date', field: 'groupEndDate', type: 'datetime' },
                        { title: 'First date', field: 'groupStartDate', type: 'datetime' },
                        { title: 'Exception', field: 'exception', cellStyle: { whiteSpace: 'pre' } }
                    ]}
                    data={exceptionsData}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete exceptions',
                            onClick: (event, rowData: any) => deleteExceptions(authContext, rowData.logIds)
                        }
                    ]}
                />
            }
        </Box>
    );
}

export default connector(Exceptions);