import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import LineChart from '../components/LineChart';
import { getChartData } from '../selectors/siteSelectors';
import { requestChartData } from '../actions/siteActions';
import { useAuth0 } from '@auth0/auth0-react';

const mapStateToProps = (state: any) => ({
    createdMultistreamsChartData: getChartData(state, 'createdMultistreamsChart'),
    multistreamVisitsChartData: getChartData(state, 'multistreamVisitsChart')
});

const mapDispatchToProps = {
    requestChartData
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DashboardProps = ConnectedProps<typeof connector> & {};

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
    const { createdMultistreamsChartData, multistreamVisitsChartData } = props;
    const { requestChartData } = props;

    const authContext = useAuth0();

    React.useEffect(() => {
        if(!authContext.isLoading) {
            requestChartData(authContext, 'createdMultistreamsChart');
            requestChartData(authContext, 'multistreamVisitsChart');
        }
    }, [authContext, requestChartData]);

    return (
        <Box>
            <LineChart title='Created multistreams' data={createdMultistreamsChartData} />
            <LineChart title='Multistream visits' data={multistreamVisitsChartData} />
        </Box>
    );
}

export default connector(Dashboard);