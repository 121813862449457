import React, { FunctionComponent } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Menu from '../components/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import siteReducer from '../reducers/siteReducer';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { setAutoFreeze } from 'immer';
import Dashboard from './Dashboard';
import Exceptions from './Exceptions';
import { Button } from '@material-ui/core';
import ButtonProgress from '../../utils/ButtonProgress';
import Notifier from '../components/Notifier';
import { SnackbarProvider } from 'notistack';

const siteTheme = createMuiTheme({
    palette: {
        background: {
            default: '#fff7ed'
        }
    }
});

setAutoFreeze(false);
const composeEnhancers = composeWithDevTools({});

const store = createStore(siteReducer, composeEnhancers(applyMiddleware(thunk)));

const menuOptions = [
    { label: 'Dashboard', link: '/' },
    { label: 'Exceptions', link: '/exceptions' },
    { label: 'Users', link: '/users' },
    { label: 'Multistreams', link: '/multistreams' },
    { label: 'Streams', link: '/streams' },
];

const App: FunctionComponent = () => {
    return (
        <Provider store={store}>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH_DOMAIN}
                clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                audience={process.env.REACT_APP_AUTH_AUDIENCE}
            >
                <SnackbarProvider maxSnack={3} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Notifier />
                    <BrowserRouter>
                        <SiteContent />
                    </BrowserRouter>
                </SnackbarProvider>
            </Auth0Provider>
        </Provider>
    );
}

const SiteContent: FunctionComponent = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <React.Fragment>
            <ThemeProvider theme={siteTheme}>
                <Box display='flex'>
                    <CssBaseline />
                    {isAuthenticated ? <SiteRoutes /> : <LoginButton />}
                </Box>
            </ThemeProvider>
        </React.Fragment>
    );
}

const SiteRoutes: FunctionComponent = () => {
    return (
        <React.Fragment>
            <Menu menuOptions={menuOptions} />
            <Box width='100%'>
                <Box mx={4} my={12} >
                    <Route exact path='/' component={Dashboard} />
                    <Route path='/exceptions' component={Exceptions} />
                </Box>
            </Box>
        </React.Fragment>
    );
}


const LoginButton: FunctionComponent = () => {
    const { isLoading, loginWithPopup } = useAuth0();

    return (
        <Box width='100%'>
            <Box my={5} textAlign='center'>
                <Button variant='contained' color='primary' onClick={loginWithPopup} disabled={isLoading}>
                    Login
                    {isLoading && <ButtonProgress />}
                </Button>
            </Box>
        </Box>
    )
}


export default App;
