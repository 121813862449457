import { SiteState } from '../types/siteState';
import { Notification } from '../types/notification';

export const getChartData = (state: SiteState, key: string): Array<any> => {
    return state.chartData[key];
};

export const getTableData = (state: SiteState, key: string): Array<any> => {
    return state.tableData[key];
};

export const getNotifications = (state: SiteState): Array<Notification> => {
    return state.notifications;
};