import reduceReducers from 'reduce-reducers';
import { createReducer } from '../../utils/reduxUtils';
import { SiteState } from '../types/siteState';
import { types } from '../actions/siteActions';
import { Notification } from '../types/notification'; 

const initialState: SiteState = {
    chartData: {},
    tableData: {},
    notifications: []
};

const siteReducer = createReducer<SiteState>({
    [types.UPDATE_CHART_DATA]: (draft, payload) => {
        draft.chartData[payload.key] = payload.data;
    },
    [types.UPDATE_TABLE_DATA]: (draft, payload) => {
        draft.tableData[payload.key] = payload.data;
    },
    [types.NOTIFY]: (draft, notification: Notification) => {
        draft.notifications.push(notification);
    },
    [types.REMOVE_NOTIFICATION]: (draft, key: string) => {
        draft.notifications = draft.notifications.filter(x => x.key !== key);
    }
});

export default reduceReducers(initialState, siteReducer);