import React, { FunctionComponent } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { Box } from '@material-ui/core';
import ButtonProgress from '../../utils/ButtonProgress';

type LineChartProps = {
    title: string,
    data: Array<any>
};

const LineChart: FunctionComponent<LineChartProps> = (props) => {
    const { title, data } = props;

    const chartData = [
        {
            "id": "data",
            "color": "hsl(57, 70%, 50%)",
            "data": data != null ? data : []
        }
    ];

    return (
        <Box height={300} width="100%" mb={4} position='relative'>
            { data == null ? <ButtonProgress /> :
                <ResponsiveLine
                    data={chartData}
                    margin={{ top: 50, right: 110, bottom: 70, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -90,
                        legend: title,
                        legendOffset: -220,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'count',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'nivo' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[]}
                />
            }
        </Box>
    );
}

export default LineChart;