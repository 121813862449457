import { action } from '../../utils/reduxUtils';
import axios from 'axios';
import moment from 'moment';
import { AppDispatch } from '../types/actionTypes';
import { VariantType } from 'notistack';
import { Notification } from '../types/notification';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '../../utils/axiosUtils';

export const types = {
    UPDATE_CHART_DATA: 'site/UPDATE_CHART_DATA',
    UPDATE_TABLE_DATA: 'site/UPDATE_TABLE_DATA',
    NOTIFY: 'site/NOTIFY',
    REMOVE_NOTIFICATION: 'site/REMOVE_NOTIFICATION'
};

export const requestTableData = (authContext: Auth0ContextInterface, url: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get(url, requestConfig);

            dispatch(action(types.UPDATE_TABLE_DATA, { key: url, data: response.data }))
        }
        catch (error) {
            console.error(error);
            dispatch(notify('Failed table data request', 'error'));
        }
    }
}

export const requestChartData = (authContext: Auth0ContextInterface, urlKey: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get('/api/admin/' + urlKey, requestConfig);
            const convertedData = response.data.map((x: { date: string; value: any; }) => ({ 
                x: moment.utc(x.date).format('YYYY-MM-DD'), 
                y: x.value 
            }));

            dispatch(action(types.UPDATE_CHART_DATA, { key: urlKey, data: convertedData }))
        }
        catch (error) {
            console.error(error);
            dispatch(notify('Failed chart data request', 'error'));
        }
    }
}

export const deleteExceptions = (authContext: Auth0ContextInterface, exceptionIds: any) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.post('/api/admin/deleteExceptions', exceptionIds, requestConfig);

            dispatch(action(types.UPDATE_TABLE_DATA, { key: '/api/admin/exceptions', data: response.data }))
            dispatch(notify('Deleted selected exceptions', 'success'));
        }
        catch (error) {
            console.error(error);
            dispatch(notify('Failed exceptions delete', 'error'));
        }
    }
}

export const notify = (message: string, type: VariantType) => {
    const key: string = '' + (new Date().getTime() + Math.random());
    return action<Notification>(types.NOTIFY, { message, type, key });
}

export const removeNotification = (key: string) => action<string>(types.REMOVE_NOTIFICATION, key);